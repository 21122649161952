import React from 'react';
import Loadable from 'react-loadable';
import {
  ComponentPreview,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import itemtitlePreviewData from '../../../../data/previews/itemtitle.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const ItemTitle = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.ItemTitle;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Item Title"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Item Title" />
      <V5Notice
        componentName="Item Title"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/typography-item-title--docs"
      />

      <PageNavigation
        links={['Size', 'Style', 'Usage', 'Microcopy', 'Platform']}
      />

      <Section title="Size">
        <Paragraph>As usual, it all depends on context.</Paragraph>
        <ComponentPreview
          name="ItemTitleSize"
          layout="default"
          previewData={itemtitlePreviewData.levels}>
          <ItemTitle>Email Address</ItemTitle>
        </ComponentPreview>
      </Section>

      <Section title="Style">
        <Paragraph>
          The all-caps variation of item title makes every letter uppercase,
          creating an extra layer of visual density not provided by the standard
          item title.
        </Paragraph>
        <ComponentPreview
          name="ItemTitleStyle"
          layout="default"
          previewData={itemtitlePreviewData.types}>
          <ItemTitle>Order Number</ItemTitle>
        </ComponentPreview>
      </Section>

      <Section title="Usage">
        <Paragraph>
          An item title is an alernative to the{' '}
          <Link href="/components/type/subhead/design">subhead</Link>. Use an
          item title when a subhead would be too visually distracting against
          the rest of the interface, or when space is at a premium. Item titles
          should not be used in place of any other type component.
        </Paragraph>
        <List>
          <li>When referencing Hudl, always use the default style.</li>
          <li>Limit the usage of all-caps style to one-word phrases.</li>
        </List>
      </Section>

      <Section title="Microcopy">
        <Paragraph>
          Use the{' '}
          <Link href="http://hudl.com/design/writing/writing-for/teams/product">
            UI label guidelines
          </Link>{' '}
          when typesetting item titles.
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
